import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/ui/Button";
import Icon from "../../../components/ui/Icon"
import { getApp } from "../../../store/slices/app.slice";
import variluxgG9Slice from "../../../store/slices/variluxG9.slice";
import useBEM from "../../../utils/hooks/useBEM"
import useTranslation from "../../../utils/hooks/useTranslation";


const IntroVarilux = () => {
  const dispatch = useDispatch()
  const [B, E] = useBEM('varilux-intro')
  const { t } = useTranslation(useSelector(getApp));
  const { translation } = useSelector(getApp)

  const nextStep = () => {
    dispatch(variluxgG9Slice.actions.changeView('needExplorer'))
  }

  useEffect(() => {
    dispatch(variluxgG9Slice.actions.resetData())
  }, [])

  const setKoreanHeaderText = () => {
    if (translation?.country === 'Korea') {
      return (
        <p>
          <span>
            <span>당신의</span> &nbsp;
            <span className={E('text-gradient')}>개인맞춤렌즈</span>
            <span>를</span>
          </span>
          <span>
            <span className={E('text-gradient')}>가상체험</span> &nbsp;
            <span>해 보세요.</span>
          </span>
        </p>
      ) 
    } else {
      return (
        <p>
          {t('need_explorer_experience_your','Experience your')}
          <span className={E('text-gradient')}>
          {t('need_explorer_personalized_demonstration','personalized demonstration')}
          </span>
        </p>
      )
    }
  }

  return (
    <div className={B()}>
      <div className={E('logo')}>
        <Icon name='variluxG9-logo-final' type='png'/>
      </div>
      <div className={E('text')}>
        {/* <p>
          {t('need_explorer_experience_your','Experience your')}
          <span className={E('text-gradient')}>
          {t('need_explorer_personalized_demonstration','personalized demonstration')}
          </span>
        </p> */}
        {setKoreanHeaderText()}
      </div>
      <Button 
        text={t('personalize_your_lenses_pyl_next','Next')}
        icon="right-arrow-circle-purple"
        className={E('button')}
        outlined={false}
        type='simple'
        click={nextStep}
        id='varilux_intro_next_button'
      />
    </div>
  )
}

export default IntroVarilux