import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import routes from './constants/routes.constant';
import iTheme from './interface/theme.interface';
// import ReactGA from 'react-ga';
//import RouteChangeTracker from './components/RouteChangeTracker';
import axios from 'axios';
import IndexedDBProvider from './utils/context/indexedDBContext';
import useIndexedDB from './utils/hooks/useIndexedDB';
import iRootState from './store/interface/root-state.interface';
// import useENV from './utils/hooks/useEnv';
import Alert from './components/ui/Alert';
import appSlice from './store/slices/app.slice';
import ExperienceType from './types/experience.type';
import Varilux from './pages/varilux-g9/Varilux';

function App() {
  const [theme, setTheme] = useState<iTheme>();
  const [globalAlert, setGlobalAlert] = useState<{
    show: boolean,
    message?: string,
    action?: () => void
  }>({
    show: false
  });
  const [experience,] = useState<ExperienceType>(ExperienceType.VARILUX);
  const [isModalOpen, setIsModalOpen] = useState<boolean>((): any => {
    store.subscribe(() => {
      const state = store.getState();
      const appStore = state.app;
      return appStore.isModalOpen
    })
  })
  const oldTheme = useRef<string>();
  // const [loading, setLoading] = useState<{
  //   show: boolean,
  //   text?: string,
  //   textOnly?: boolean
  // }>({ show: false });

  const loadingPrevValue = useRef<boolean>(false);
  const [db, setDB] = useState<IDBDatabase | undefined>(undefined);
  const dbRef = useRef<IDBDatabase | undefined>(db);
  const { connect, createTables } = useIndexedDB();
//   const env = useENV();

  useEffect(() => {
    watchAppTheme();
    axiosInterceptors();

    async function connectIndexDB() {
      const indexDB = await connect((db: any) => createTables(db));
      setDB(indexDB)
    }
    connectIndexDB();
  }, []);

  useEffect(() => {
    dbRef.current = db;
    initializeGoogleAnalytics();
    if (!db) return;

    const isIndexedDbDataFixed = localStorage.getItem('isIndexedDbDataFixed');
    if (isIndexedDbDataFixed) return;
    localStorage.setItem('isIndexedDbDataFixed', 'true');
  }, [db]);

  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState();
      const appStore = state.app;
      setIsModalOpen(appStore.isModalOpen)
    }
    )
  }, [isModalOpen])

  const initializeGoogleAnalytics = () => {   
    // const gaCode = accountDetails?.ga_code;
    //for custom GA code
    // ReactGA.initialize( (gaCode !== undefined || gaCode !== "" ? gaCode : env.GA) ||  env.GA);
    //ReactGA.initialize(env.GA);
    //ReactGA.pageview('/');
  }

  const axiosInterceptors = () => {
    let isBackground = false;
    axios.interceptors.request.use(
      (req) => {
        //const token = store.getState().app.token;
        const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJFc3NpbG9yIENvbXBhbmlvbiIsImF1ZCI6IiIsImF2YWlsYWJsZV9kYXRlIjoiMjAyMS0wNi0yOSIsImlzcyI6InVybjpcL1wvYXBpZ2VlLWVkZ2UtZXNzaWxvci1KV1QiLCJtYWNoaW5lX2lkIjoiNjk3ZDMwMmEtMDBjNC00OWY1LWI3YTctNzkyMWJhZGQ3OTQzIiwic3RhdGUiOiI4IiwiZXhwaXJhdGlvbl9kYXRlIjoiMjAyNS0wMS0wMSIsImlhdCI6MTY4MzYwNTgyMCwianRpIjoiZmU2M2NjZjctZGMwYS00MzE1LTk5YWYtYWJkZDg5ODUwNjc3IiwibGljZW5zZV9udW1iZXIiOiJYWFhYLVhYWFgtWFhYWC1YWFhYIn0.yTyh9V0nsqB0Iq7IvfJbVeaII2IMeTyLQNLcpeza7hJANrNhgP7YwIp3O_a1LwXGKvSwtfIXk6dogDnIUYUYJpE3gas8oxE-jPmlt8Ly82Gqd0ULHb5s-iGDaLaI2G8m5jAkfHpT2diAi9qRf2iZ_yVsT1Pb3vDoRjWBbBrg1tXQA5dpavmEMgZ5Z0VW2pYpG2u06kM0y1Mo7i7aYTcD9Z1xsZ86nn8BYUq0wFFWZkB-9KXTrpkBiSyqeemRfqo1xuDQteNcOo2tjP6F40YIo-Db5bt_Df2zbpxP_G82iFtVffTB9hnaXx_Tm89jzC917HyL0Cr8aHNv55S0A7cbOg"
        isBackground = req.headers['background'];
        delete req.headers['background'];
        const requiredToken = req.headers['Authorization'];
        if (requiredToken) req.headers['Authorization'] = `Bearer ${token}`;
        //if (!isBackground) setLoading({ show: true });
        return req;
      },
      (err) => {
        //if (!isBackground) setLoading({ show: false });
        return Promise.reject(err);
      }
    );
    axios.interceptors.response.use(
      (res) => {
        // Add configurations here
        // if (res.status === 201 || res.status === 200) {
        //   if (!isBackground) setLoading({ show: false });
        // }
        return res;
      },
      (err) => {
        //if (!isBackground) setLoading({ show: false });
        return Promise.reject(err);
      }
    );
  }


  let throttle: any;
  const saveToLocalStorage = (state: iRootState) => {
    clearTimeout(throttle);
    throttle = setTimeout(() => {
      try {
        const serialisedState = JSON.stringify(state);
        localStorage.setItem("persistantState", serialisedState);
      } catch (e) {
        console.warn(e);
      }
    }, 250);
  }

  const watchAppTheme = () => {
    store.subscribe(() => {
      const state = store.getState();

      const appStore = state.app;
      const currentTheme = appStore.theme;
      // Note: update theme only when theme state is changed
      if (!oldTheme.current || oldTheme.current !== currentTheme.id) {
        setTheme(currentTheme);
        oldTheme.current = currentTheme.id;
      }

      if (appStore.loading.show === undefined) return;
      if (appStore.loading.show !== loadingPrevValue.current) {
        //setLoading(appStore.loading);
        loadingPrevValue.current = appStore.loading.show
      }
      saveToLocalStorage(state);
      setGlobalAlert(appStore.globalAlert);
    });
    setTheme(store.getState().app.theme)
  }

  if (!db) return null;
  return (
    <div className={`App App--${experience}${isModalOpen ? ` App--fixed` : ''}`} style={{
      "--app-theme-main": theme?.main,
      "--app-theme-dark": theme?.dark,
      "--app-bubble-main": theme?.bubbleMain,
      "--app-bubble-sub": theme?.bubbleSub,
      "--app-bubble": theme?.bubbleSub,
    } as React.CSSProperties}>

      <Provider store={store} >
        <IndexedDBProvider db={db}>
          <Router>
            {/* <RouteChangeTracker /> */}
            <Switch>
              <Route path={routes.home.path} exact component={Varilux} />
            </Switch>
            <Alert
              show={globalAlert.show}
              message={globalAlert.message || ''}
              buttons={[{
                label: 'Ok',
                id: 'ok'
              }]}
              onButtonSelect={() => {
                if (globalAlert.action) globalAlert.action();
                store.dispatch(appSlice.actions.showGlobalAlert({ show: false }))
              }}
            />
          </Router>
        </IndexedDBProvider>
      </Provider>
    </div>
  );
}

export default App;
